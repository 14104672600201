import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

const ContactButton = ({ label, mail, className }) => (

  <a className={`button button-animate mt-8 ${className}`} href={`mailto:${mail}`}>
    {label}
    <FontAwesomeIcon icon={faArrowRight} />
  </a>
);


ContactButton.propTypes = {
  label: PropTypes.string,
  mail: PropTypes.string,
  className: PropTypes.string,
};

ContactButton.defaultProps = {
  label: 'Kontaktiere uns',
  mail: 'hello@hochzehn.com',
  className: '',
};

export default ContactButton;
