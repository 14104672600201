import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import rehypeReact from "rehype-react"
import JoinButton from '../components/misc/JoinButton';

export default function Template({
                                   data, // this prop will be injected by the GraphQL query below.
                                 }) {
  const { markdownRemark } = data;// data.markdownRemark holds our post data
  const { frontmatter, htmlAst } = markdownRemark;
  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: {
      "join-button": JoinButtonRow,
      "join-hochzehn-intro": JoinHochzehnIntro,
    },
  }).Compiler;

  return (
    <Layout>
      <div className="flex flex-col">
        <div className="flex flex-row relative">
          <div className="flex flex-col items-start max-w-md">
            <h2 className="mt-12">{frontmatter.title}</h2>
          </div>
        </div>
        <h4 className="mt-4">{frontmatter.date}</h4>
        <div className="page-text mt-12 cursor-auto animate">
          {renderAst(htmlAst)}
        </div>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
    query($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            htmlAst
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                path
                title
            }
        }
    }
`;

export function JoinHochzehnIntro() {
  return (
    <div className="mb-8">
      <p>Wir lieben es, mit motivierten, inspirierten Menschen zusammen zu arbeiten und Visionen zu verwirklichen. Wir sind begeistert von Technologien und überzeugt davon, mit ihrem Einsatz zusammen großartige Produkte zu erschaffen.</p>
      <p>Klingt nach dir? Werde ein Teil unseres Teams!</p>
    </div>
  );
}

export function JoinButtonRow() {
  return (
    <div className="flex flex-row justify-center">
      <JoinButton/>
    </div>
  );
}
